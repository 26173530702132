
import './App.css';
//
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import Error from './pages/Error';
import {Link, BrowserRouter, Routes, Route} from 'react-router-dom';

function App() {
  return (
  
    <div className="App">
    <BrowserRouter>
    
     <div>
       <ul>
           <li> <Link className='link' to="/">Home</Link></li>
           <li> <Link className='link' to="/about">About</Link></li>
           <li> <Link className='link' to="/blog">Blog</Link></li>
          
       </ul>
      </div> 


      <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route path="about" element={<About />}></Route>
      <Route path="blog" element={<Blog />}></Route>
      <Route path="*" element={<Error />}></Route>

     </Routes>
   </BrowserRouter>
 </div>
  );
}
export default App;
