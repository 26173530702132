// @flow strict

import * as React from 'react';

class Blog extends React.Component<Props> {
    render() {
        return (
            <div>
                <h1>Blog</h1>  
            </div>
        );
    }
}

export default Blog;