const Error = ()=>{

    return(
        <section>
            <h2>404</h2>
            <p>Page not found</p>
        </section>
    );
}

export default Error ;
